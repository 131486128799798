import { GET_CAPTCHA_LOADED, GET_CAPTCHA_WAITING, GET_CAPTCHA_ERROR } from "./types"

const initState = {
    status:false,
    image:""
}

const CaptchaReducer = (state = initState, action) => {
    switch(action.type){
        case GET_CAPTCHA_LOADED:
            return {
                ...state,
                status:true,
                image:action.image
            }
        case GET_CAPTCHA_WAITING:
            return {
                ...state,
                status:false,
                image:"Captcha loading..."
            }
        case GET_CAPTCHA_ERROR:
            return {
                ...state,
                status:false,
                image:"Error during captcha loading, please refresh this page."
            }
        default:
            return state;
    }
}

export default CaptchaReducer;