import {
  SHOW_BITQUERY_SUCCESS,
  SHOW_BITQUERY_FAILED,
  SHOW_BITQUERY_RELOAD,
} from "./types";

const initState = {
  data: null,
  loaded: false,
};

const BitQueryLoadReducer = (state = initState, action) => {
  switch (action.type) {
    case SHOW_BITQUERY_SUCCESS:
      return {
        ...state,
        data: action.response,
        loaded: true,
      };
    case SHOW_BITQUERY_FAILED:
      return {
        ...state,
        data: null,
        loaded: true,
      };
    case SHOW_BITQUERY_RELOAD:
      return {
        ...state,
        data: null,
        loaded: false,
      };
    default:
      return state;
  }
};

export default BitQueryLoadReducer;
