import {
  ALLOWED_VIEW_CLEAN_STATE,
  ALLOWED_VIEW_FAILED,
  ALLOWED_VIEW_SUCCESS,
} from "./types";

const initState = {
  tabs: null,
  loaded: false,
  error: false,
};

const AllowedViewReducer = (state = initState, action) => {
  switch (action.type) {
    case ALLOWED_VIEW_SUCCESS:
      return {
        ...state,
        tabs: action.response,
        loaded: true,
        error: false,
      };
    case ALLOWED_VIEW_FAILED:
      return {
        ...state,
        tabs: null,
        loaded: true,
        error: true,
      };
    case ALLOWED_VIEW_CLEAN_STATE:
      return {
        ...state,
        tabs: null,
        loaded: false,
        error: false,
      };
    default:
      return state;
  }
};

export default AllowedViewReducer;
