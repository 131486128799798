import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Animation from "./views/layout/Animation";
import { AnimatePresence } from "framer-motion";
import ShowLoading from "./views/Loading";

const Login = lazy(() => import("./views/Auth/Login"));

const Overview = lazy(() => import("./views/Overview"));
const OverviewStats = lazy(() => import("./views/Overview/Statistics"));
const OverviewCustomers = lazy(() => import("./views/Overview/Customers"));

const UsersList = lazy(() => import("./views/Users/List"));
const UserManage = lazy(() => import("./views/Users/Manage"));
const UserProfile = lazy(() => import("./views/Users/Manage/Profile"));
const UserPlan = lazy(() => import("./views/Users/Manage/Plan"));
const UserRules = lazy(() => import("./views/Users/Manage/Rules"));
const UserACL = lazy(() => import("./views/Users/Manage/ACL"));
const UserAPI = lazy(() => import("./views/Users/Manage/API"));

const News = lazy(() => import("./views/News"));

const Plans = lazy(() => import("./views/Plans"));
const PlansTemplate = lazy(() => import("./views/Plans/Template"));
const PlansDiscount = lazy(() => import("./views/Plans/Discount"));

const Invoices = lazy(() => import("./views/Invoices/List"));
const ShowInvoice = lazy(() => import("./views/Invoices/Manage"));

const Methods = lazy(() => import("./views/Methods"));
const MethodsAll = lazy(() => import("./views/Methods/All/Method"));
const MethodsGroupsAll = lazy(() => import("./views/Methods/All/Group"));
const MethodsOptionsAll = lazy(() => import("./views/Methods/All/Options"));

const Attacks = lazy(() => import("./views/Attacks"));
const Blacklist = lazy(() => import("./views/Blacklist"));

const Resources = lazy(() => import("./views/Resources"));
const ResourcesHookAll = lazy(() => import("./views/Resources/All/Hook"));
const ResourcesServersAll = lazy(() => import("./views/Resources/All/Servers"));
const ResourcesAPIAll = lazy(() => import("./views/Resources/All/API"));
const ResourcesSocketAll = lazy(() => import("./views/Resources/All/Socket"));
const ResourcesServerCmd = lazy(() => import("./views/Resources/All/CMD"));

const Support = lazy(() => import("./views/Support"));
const SupportChat = lazy(() => import("./views/Support/Chat"));
const SupportPresets = lazy(() => import("./views/Support/Presets"));
const SupportAgents = lazy(() => import("./views/Support/Agent"));
const SupportTickets = lazy(() => import("./views/Support/Ticket"));

const Settings = lazy(() => import("./views/Settings"));
const SettingsOverview = lazy(() => import("./views/Settings/Overview"));
const SettingsCryptos = lazy(() => import("./views/Settings/Cryptos"));

export function WebRoutes() {
  return (
    <Router>
      <AnimatePresence>
        <Suspense fallback={<ShowLoading />}>
          <Routes>
            <Route element={<Animation />}>
              <Route path="/login" element={<Login />} />

              <Route path="/overview" element={<Overview />}>
                <Route index element={<OverviewStats />} />
                <Route path="customers" element={<OverviewCustomers />} />
                <Route path="*" element={<Navigate to="/overview" />} />
              </Route>

              <Route path="/users" element={<UsersList />} />

              <Route path="/user" element={<UserManage />}>
                <Route path="profile/:id" element={<UserProfile />} />
                <Route path="plan/:id" element={<UserPlan />} />
                <Route path="rules/:id" element={<UserRules />} />
                <Route path="acl/:id" element={<UserACL />} />
                <Route path="api/:id" element={<UserAPI />} />
                <Route path="*" index element={<Navigate to="/users" />} />
              </Route>

              <Route path="/news" element={<News />} />

              <Route path="/plans" element={<Plans />}>
                <Route index element={<PlansTemplate />} />
                <Route path="discount" element={<PlansDiscount />} />
                <Route path="*" element={<Navigate to="/plans" />} />
              </Route>

              <Route path="/invoices" element={<Invoices />} />
              <Route path="/invoice/:id" element={<ShowInvoice />} />

              <Route path="/methods" element={<Methods />}>
                <Route index element={<MethodsAll />} />
                <Route path="groups" element={<MethodsGroupsAll />} />
                <Route path="options" element={<MethodsOptionsAll />} />
                <Route path="*" element={<Navigate to="/methods" />} />
              </Route>

              <Route path="/attacks" element={<Attacks />} />

              <Route path="/blacklist" element={<Blacklist />} />

              <Route path="/resources" element={<Resources />}>
                <Route index element={<ResourcesHookAll />} />
                <Route path="servers" element={<ResourcesServersAll />} />
                <Route path="cmd" element={<ResourcesServerCmd />} />
                <Route path="api" element={<ResourcesAPIAll />} />
                <Route path="socket" element={<ResourcesSocketAll />} />
                <Route path="*" element={<Navigate to="/resources" />} />
              </Route>

              <Route path="/support" element={<Support />}>
                <Route index element={<SupportChat />} />
                <Route path="presets" element={<SupportPresets />} />
                <Route path="agents" element={<SupportAgents />} />
                <Route path="tickets" element={<SupportTickets />} />
                <Route path="*" element={<Navigate to="/support" />} />
              </Route>

              <Route path="/settings" element={<Settings />}>
                <Route index element={<SettingsOverview />} />
                <Route path="cryptos" element={<SettingsCryptos />} />
                <Route path="*" element={<Navigate to="/settings" />} />
              </Route>
            </Route>
            <Route index element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </AnimatePresence>
    </Router>
  );
}
